<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="角色编号:" prop="RoleID">
              <Input
                placeholder="请输入角色编号"
                v-model="params.RoleID"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="角色名称:" prop="RoleName">
              <Input
                placeholder="请输入角色名称"
                v-model="params.RoleName"
                clearable
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="top" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            class="ml2"
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Sys_Role_Edit'])"
            @click="handleAddNew"
            >新增</Button
          >
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2 search-form-container__button" @click="handleReset"
            >重置</Button
          >
        </Col>
      </Row>
    </Form>

    <Table
      stripe
      class="mt10 main-table"
      :loading="loading"
      :height="tableHeight"
      ref="table"
      :columns="columns"
      @on-sort-change="sortChange"
      :data="roleList.DataList"
      highlight-row
      size="small"
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          class="ml2"
          type="success"
          v-if="$canAction(['Sys_Role_Edit'])"
          @click="handleOpenEdit(row)"
          >编辑</Button
        >
        <Button
          size="small"
          class="ml2"
          type="error"
          v-if="$canAction(['Sys_Role_Del'])"
          @click="handleOpenDelete(row)"
          >删除</Button
        >
        <Button
          size="small"
          class="ml2"
          type="primary"
          v-if="$canAction(['Sys_Role_Edit'])"
          @click="handleOpenAllUser(row)"
          >用户</Button
        >
        <Button
          size="small"
          class="ml2"
          type="primary"
          v-if="$canAction(['Sys_Role_Edit'])"
          @click="setUCClick(row)"
          >用例</Button
        >
      </template>
    </Table>
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="roleList.TotalCount"
          :current="roleList.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <Modal v-model="SetUC" title="设置用例" class-name="i-modal" width="1000">
      <div class="overflow-y">
        <Form label-position="left" :label-width="120" class="modelViewFrom">
          <CheckboxGroup @on-change="selectChangeList" v-model="NowCheckList">
            <FormItem
              v-for="(item, index) in allUseCaseList"
              :key="index"
              :label="item.ModuleTitle"
              style="border-bottom: 1px #e9eaec solid"
            >
              <Checkbox
                v-for="(item1, index) in item.Children"
                :key="index"
                :label="item1.UsecaseID"
                >{{ item1.UsecaseTitle }}</Checkbox
              >
            </FormItem>
          </CheckboxGroup>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="SetUC = false">取消</Button>
        <Button
          @click="
            NowCheckList = [];
            selectChangeList(NowCheckList);
          "
          type="error"
          >全不选</Button
        >
        <Button
          @click="
            allUseCaseSource.map((item) => {
              NowCheckList.push(item.UsecaseID);
            });
            selectChangeList(NowCheckList);
          "
          type="success"
          >全选</Button
        >
        <Button type="primary" @click="SetUsecase" :loading="setLoading">
          {{ !SaveLoading ? "保存" : "保存中..." }}
        </Button>
      </div>
    </Modal>
    <Modal v-model="SysRoleView" title="编辑角色信息" class-name="i-modal">
      <Form
        ref="form"
        :model="fromrow"
        class="modelViewFromCheck"
        :rules="ruleValidate"
      >
        <Row :gutter="16">
          <Col span="12">
            <FormItem label="所属系统">
              <Input type="text" value="六十六号商行分销系统" disabled></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="角色编号">
              <Input type="text" v-model="fromrow.RoleID" disabled></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="12">
            <FormItem prop="RoleName" label="角色名称">
              <Input type="text" v-model="fromrow.RoleName"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="角色描述">
              <Input type="text" v-model="fromrow.RoleDesc"></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button @click="SysRoleView = false">取消</Button>
        <Button type="primary" @click="handleSave" :loading="SaveLoading">
          {{ !SaveLoading ? "保存" : "保存中..." }}
        </Button>
      </div>
    </Modal>
    <role-user-modal @on-updated="onUpdated" ref="roleUserModal" />
  </Layout>
</template>

<script>
import layout from "@/components/layout/index.vue";
import api from "@/api";
import roleUserModal from "./components/role-user-modal.vue";
export default {
  name: "RoleManage",
  data() {
    return {
      isSaving: false,
      SaveLoading: false,
      isNew: false,
      SetUC: false,
      setLoading: false,
      SysRoleView: false,
      tableHeight: 0,
      fromrow: {},
      params: {
        RoleID: "",
        RoleName: "",
        Page: 1,
        PageSize: 10,
        SortName: "RoleID",
        SortOrder: "ASC",
      },
      paramsUser: {
        roleID: "",
        Page: 1,
        PageSize: 10,
        SortName: "ID",
        SortOrder: "Asc",
        contain: false,
      },
      roleList: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      VariationUsecaseList: [],
      NowCheckListSource: [],
      NowCheckList: [],
      allUserList: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      allUseCaseList: [],
      allUseCaseSource: [],
      loading: false,
      loadingSet: false,
      SetUserModel: false,

      AddCheckList: [], // 新增的选项
      DelCheckList: [], // 移除的选项
      selectedObj: {},
      ruleValidate: {
        RoleName: [
          { required: true, message: "角色名称不能为空", trigger: "blur" },
        ],
      },
      columns: [
        {
          title: "角色编号",
          key: "RoleID",

          minWidth: 80,
          align: "center",
          sortable: "custom",
        },
        {
          title: "角色名称",
          key: "RoleName",

          align: "left",
          sortable: "custom",
          minWidth: 100,
        },
        {
          title: "角色描述",
          key: "RoleDesc",

          align: "left",
          minWidth: 120,
        },
        {
          title: "角色内用户",
          key: "UserRoleStr",

          align: "left",
          // slot: "RoleUsers",
          minWidth: 350,
        },
      ],
    };
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.getRoleList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.getRoleList();
        if (this.allUseCaseList.length <= 0) {
          this.getAllUseCaseList();
        }
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.getRoleList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      let addition = 0;
      if (this.$canAction(["Sys_Role_SetUser"])) addition += 25;
      if (this.$canAction(["Sys_Role_SetUC"])) addition += 25;
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth + addition,
        maxWidth: this.$route.meta.tableActionWidth + addition,
        fixed: "right",
      });
    }
    this.getRoleList();
    if (this.allUseCaseList.length <= 0) {
      this.getAllUseCaseList();
    }
  },
  methods: {
    handleSearch() {
      this.params.Page = 1;
      this.getRoleList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.params.Page = 1;
      this.getRoleList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.getRoleList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.getRoleList();
    },
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.getRoleList();
    },
    handleOpenEdit(row) {
      this.$refs.form.resetFields();
      this.fromrow = JSON.parse(JSON.stringify(row));
      this.SysRoleView = true;
    },
    async handleSave() {
      const _this = this;
      _this.$refs.form.validate(async (valid) => {
        if (valid) {
          _this.SaveLoading = true;
          try {
            var result = await api.SaveSysRole({
              entity: _this.fromrow,
            });
            if (result.Status === 100) {
              _this.SaveLoading = false;
              _this.$Message.success("保存成功");
              _this.SysRoleView = false;
              const old = _this.roleList.DataList.find(
                (item) => item.RoleID === result.Data.RoleID
              );
              if (old) {
                old.RoleName = result.Data.RoleName;
                old.RoleDesc = result.Data.RoleDesc;
                _this.roleList.DataList.splice(
                  _this.roleList.DataList.findIndex(
                    (item) => item.RoleID === result.Data.RoleID
                  ),
                  1,
                  old
                );
              } else {
                _this.roleList.DataList.push(result.Data);
              }
            } else {
              _this.$Message.error(result.Msg);
              _this.SaveLoading = false;
            }
          } catch (error) {
            _this.$Notice.error({ title: "错误", desc: error });
            _this.SaveLoading = false;
          }
        } else {
          _this.$Message.error("填写信息有误！");
        }
      });
    },
    async handleAddNew() {
      this.$refs.form.resetFields();
      try {
        const res = await api.GenerateSystemCode({
          strOperationCode: "RoleCode",
        });
        if (res.Status === 100) {
          this.fromrow = { RoleID: res.Data, IsAdd: true };
          this.NowCheckListSource = [];
          this.NowCheckList = [];
          this.AddCheckList = [];
          this.DelCheckList = [];
          this.userListOfRole = [];
          this.UseCaseOfSelected = [];
          this.SysRoleView = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    handleOpenDelete(row) {
      const _this = this;
      this.$Modal.confirm({
        title: "提示",
        closable: true,
        loading: true,
        content: "确定删除角色【" + row.RoleName + "】吗？",
        onOk: function () {
          _this.handleDelete(row.RoleID);
        },
        onCancel: () => {
          _this.$Modal.remove();
        },
      });
    },
    async handleDelete(id) {
      try {
        const _this = this;
        const res = await api.DeleteRole({
          id: id,
        });
        if (res.Status === 100) {
          this.$Message.success("删除成功");
          this.$Modal.remove();
          this.roleList.DataList.splice(
            this.roleList.DataList.findIndex((item) => item.RoleID === id),
            1
          );
        } else {
          _this.$Modal.remove();
          _this.isSaving = false;
          _this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.isSaving = false;
        this.$Modal.remove();
      }
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.getRoleList();
      });
    },
    async SetUsecase() {
      const _this = this;
      try {
        if (_this.AddCheckList || _this.DelCheckList) {
          _this.setLoading = true;
          const res = await api.SetModuleUsecaseToRole({
            roleID: _this.selectedObj.RoleID,
            addlist: _this.AddCheckList,
            dellist: _this.DelCheckList,
          });
          if (res.Status === 100) {
            _this.$Message.success("操作成功~");
            _this.setLoading = false;
            _this.SetUC = false;
          } else {
            _this.setLoading = false;
            _this.$Message.error(res.Msg);
          }
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.setLoading = false;
      }
    },
    async handleAddUser(row) {
      try {
        const _this = this;
        const res = await api.AddUserIntoRole({
          userid: row.ID,
          roleID: this.selectedObj.RoleID,
        });
        if (res.Status === 100) {
          this.$Message.success("添加成功");
          this.$Modal.remove();
          this.userListOfRole.push(row);
          this.SetUserModel = false;
        } else {
          _this.$Modal.remove();
          _this.isSaving = false;
          _this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.isSaving = false;
        this.$Modal.remove();
      }
    },
    async setUCClick(row) {
      this.selectedObj = row;
      try {
        this.getCheckNodeList(row.RoleID);
      } catch (error) {
        this.loading = false;
      }
    },
    // 选项被改变
    selectChangeList(checklist) {
      const _this = this;
      _this.AddCheckList = [];
      _this.DelCheckList = [];
      if (checklist) {
        checklist.map((item) => {
          if (_this.NowCheckListSource.indexOf(item) === -1) {
            _this.AddCheckList.push(item);
          }
        });
      } else {
        _this.AddCheckList = [];
      }
      if (_this.allUseCaseList) {
        _this.NowCheckListSource.map((item) => {
          if (checklist.indexOf(item) === -1) {
            _this.DelCheckList.push(item);
          }
        });
      } else {
        _this.DelCheckList = [];
      }
    },
    async getAllUseCaseList() {
      try {
        var response = await api.GetUsecaseAndRoleCaseList();
        if (response.Status === 100) {
          var datalist = JSON.parse(response.Data);
          this.allUseCaseSource = datalist;
          let temp = [];
          datalist.map((item) => {
            if (
              temp.indexOf(
                temp.find((t) => {
                  return t.PID === item.PID;
                })
              ) === -1
            ) {
              temp.push(item);
            }
          });
          temp.map((item) => {
            item.Children = datalist.filter((item1) => {
              return item.PID === item1.PID;
            });
          });
          this.allUseCaseList = temp;
        }
      } catch (error) {
        this.$Message.error(error);
      }
    },
    async getCheckNodeList(RoleID) {
      try {
        var response = await api.GetUsecaseAndRoleCaseList({
          roleID: RoleID,
        });
        if (response.Status === 100) {
          var datalist = JSON.parse(response.Data);
          this.NowCheckList = datalist;
          this.NowCheckListSource = JSON.parse(JSON.stringify(datalist));
          this.SetUC = true;
        }
      } catch (error) {
        this.$Message.error(error);
      }
    },

    async getRoleList() {
      try {
        this.loading = true;
        const res = await api.GetPagedSysRoleList(this.params);
        if (res.Status === 100) {
          res.Data.DataList.forEach((item) => {
            item.UserRoleStr = item.UserNames.join(",");
          });
          this.roleList = res.Data;
        }
        this.loading = false;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.loading = false;
      }
    },
    async loadingData() {
      try {
        this.loadingSet = true;
        const res = await api.GetUserListByRole(this.paramsUser);
        if (res.Status === 100) {
          this.allUserList = res.Data;
          this.loadingSet = false;
          this.SetUserModel = true;
        }
      } catch (error) {
        this.loadingSet = false;
      }
    },
    async handleOpenAllUser(row) {
      this.$refs.roleUserModal.obj = row;
      this.$refs.roleUserModal.isShow = true;
    },
    // 分页方法
    changePage(page) {
      this.paramsUser.Page = page;
      this.loadingData();
    },
    // 每页条数--回调
    changePageSize(pagesize) {
      this.paramsUser.PageSize = pagesize;
      this.loadingData();
    },
  },
  components: { roleUserModal, layout },
};
</script>

<style lang="less">
.tree-content:hover {
  background: #e8eaec !important;
}
</style>
